import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventhandlerService } from '../../provider/eventhandler.service';
import { GrundstueckeComponent } from '../grundstuecke/grundstuecke.component';

@Component({
  selector: 'app-itemmenu',
  templateUrl: './itemmenu.component.html',
  styleUrls: ['./itemmenu.component.scss'],
})
export class ItemmenuComponent implements OnInit {
  public static visible = true;

  public appPages = [
    {
      title: 'Grundstück',
      emit: 'grundstuecke'
    }
  ];

  constructor(public modalController: ModalController, public eventService: EventhandlerService) {
  }

  ngOnInit() {}

  clickEvent(emitVal) {
    this.eventService.changeMenuEmit(emitVal);
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: GrundstueckeComponent
    });
    return await modal.present();
  }

}
