import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventhandlerService {

  public eventNewProject;
  public changeMenu;
  public newObject;
  public newFormedObject;
  public newSemiObject;
  public newQuarterObject;
  public saveCanvas;
  public loadModal;
  public eventCollectionNewItem;

  public savedCanvasJson: any;
  public loadedId: any;
  public loadedGroup: any;
  public loadedStatus: any;
  public savedInfoJson: any = {
    anrede: null,
    projektnr: null,
    anschrift: null,
    telefon: null,
    fax: null,
    email: null,
    wasserversorgung: null,
    wasserdruck: null,
    wasserdurchflussmenge: null,
    erdbodentyp: null,
    gefaelle: null,
    steuerungsart: null,
    innenraum: null,
    aussenbereich: null,
    verteilerinnenraum: null,
    verteileraussenbereich: null,
    wandmontage: null,
    ebenerdig: null,
    microbewasser: null,
    zusatz: null,
    hasinfo: false
  };

  public loadInfoJson: any;
  public eventLoadProject: any;

  public createPdf: any;
  public admin: any = false;

  constructor() {
    this.eventNewProject = new Subject();
    this.changeMenu = new Subject();
    this.newObject = new Subject();
    this.newFormedObject = new Subject();
    this.newSemiObject = new Subject();
    this.newQuarterObject = new Subject();
    this.saveCanvas = new Subject();
    this.createPdf = new Subject();
    this.eventLoadProject = new Subject();
    this.loadModal = new Subject();
    this.eventCollectionNewItem = new Subject();
    this.savedCanvasJson = null;
    this.loadedId = 0;
    this.loadedGroup = null;
    this.loadedStatus = 1;
    this.savedInfoJson = {
      anrede: null,
      projektnr: null,
      anschrift: null,
      telefon: null,
      fax: null,
      email: null,
      wasserversorgung: null,
      wasserdruck: null,
      wasserdurchflussmenge: null,
      erdbodentyp: null,
      gefaelle: null,
      steuerungsart: null,
      innenraum: null,
      aussenbereich: null,
      verteilerinnenraum: null,
      verteileraussenbereich: null,
      wandmontage: null,
      ebenerdig: null,
      microbewasser: null,
      zusatz: null,
      hasinfo: false
    };
    this.loadInfoJson = null;
    this.admin = false;
  }

  eventNewProjectEmit(val) {
    this.eventNewProject.next(val);
  }

  changeMenuEmit(val) {
    this.changeMenu.next(val);
  }

  addNewObject(val) {
    this.newObject.next(val);
  }

  addNewSemiObject(val) {
    this.newSemiObject.next(val);
  }

  addNewQuarterObject(val) {
    this.newQuarterObject.next(val);
  }

  addNewForm(val) {
    this.newFormedObject.next(val);
  }

  saveCanvasEmit(val) {
    this.saveCanvas.next(val);
  }

  createPdfEmit(val){
    this.createPdf.next(val);
  }

  loadCanvasEmit(val) {
    this.eventLoadProject.next(val);
  }

  loadNewModal(val) {
    this.loadModal.next(val);
  }

  eventNewItem(val) {
    this.eventCollectionNewItem.next(val);
  }
}
