import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home/:width/:height',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  { path: 'new-project', loadChildren: './page/new-project/new-project.module#NewProjectPageModule' },
  { path: 'creationwizard', loadChildren: './page/creationwizard/creationwizard.module#CreationwizardPageModule' },
  { path: 'sendoffer', loadChildren: './page/sendoffer/sendoffer.module#SendofferPageModule' },
  { path: 'help', loadChildren: './page/help/help.module#HelpPageModule' },
  { path: 'imprint', loadChildren: './page/imprint/imprint.module#ImprintPageModule' },
  { path: 'privacy', loadChildren: './page/privacy/privacy.module#PrivacyPageModule' },
  { path: 'kundendaten', loadChildren: './page/kundendaten/kundendaten.module#KundendatenPageModule' },
  { path: 'grundinformationen', loadChildren: './page/grundinformationen/grundinformationen.module#GrundinformationenPageModule' },
  { path: 'projektinformationen', loadChildren: './page/projektinformationen/projektinformationen.module#ProjektinformationenPageModule' },
  { path: 'admin', pathMatch: 'full', loadChildren: './admin/admin.module#AdminPageModule' },
  { path: 'loginform', loadChildren: './loginform/loginform.module#LoginformPageModule' },
  { path: 'textinfo', loadChildren: './textinfo/textinfo.module#TextinfoPageModule' },
  { path: 'individualinfo', loadChildren: './individualinfo/individualinfo.module#IndividualinfoPageModule' },
  { path: 'saveas', loadChildren: './saveas/saveas.module#SaveasPageModule' },
  { path: 'changetext', loadChildren: './changetext/changetext.module#ChangetextPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
