import { Component, OnInit } from '@angular/core';
import { EventhandlerService } from '../../provider/eventhandler.service';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-objekte',
  templateUrl: './objekte.component.html',
  styleUrls: ['./objekte.component.scss'],
})
export class ObjekteComponent implements OnInit {

  public visible = false;
  public appPages = [
    {
      title: 'Haus',
      png: 'Haus'
    },
    {
      title: 'Carport',
      png: 'CarPort'
    },
    {
      title: 'Nebengebäude',
      png: 'Nebengebaeude'
    },
    {
      title: 'Pool/Teich',
      png: 'Teich'
    },
    {
      title: 'Hecke',
      png: 'Hecke'
    },
    {
      title: 'Busch',
      png: 'Busch'
    },
    {
      title: 'Baum klein',
      png: 'KleinerBaum'
    },
    {
      title: 'Baum groß',
      png: 'GrosserBaum'
    },
    {
      title: 'Sitzecke',
      png: 'Sitzecke'
    },
    {
      title: 'Grill',
      png: 'Grill'
    }
  ];

  constructor(public eventService: EventhandlerService, public menuCtrl: MenuController) {
  }

  ngOnInit() {}

  clickEvent(emitVal) {
    this.add(emitVal);
    this.menuCtrl.close().then((closed) => {
      if (closed) {
        this.eventService.changeMenuEmit('app-itemmenu');
      }
    });
  }

  back() {
    this.eventService.changeMenuEmit('app-itemmenu');
  }

  add(val) {
    this.eventService.addNewObject(val);
  }

  changeVisible() {
    this.visible = !this.visible;
  }

  openInfoBox() {
    this.eventService.loadNewModal('Info');
  }
}
