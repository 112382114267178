import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ItemmenuComponent } from './component/itemmenu/itemmenu.component';
import { GrundstueckeComponent } from './component/grundstuecke/grundstuecke.component';
import { ObjekteComponent } from './component/objekte/objekte.component';
import { WasserComponent } from './component/wasser/wasser.component';
import { HttpClientModule } from '@angular/common/http';
import { TippyModule } from 'ng-tippy';

@NgModule({
  declarations: [AppComponent, ItemmenuComponent, GrundstueckeComponent, ObjekteComponent, WasserComponent],
  entryComponents: [GrundstueckeComponent, ObjekteComponent, WasserComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TippyModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
