import { Component, OnInit } from '@angular/core';
import { EventhandlerService } from '../../provider/eventhandler.service';
import { MenuController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-wasser',
  templateUrl: './wasser.component.html',
  styleUrls: ['./wasser.component.scss'],
})
export class WasserComponent implements OnInit {

  public visible = false;
  public appPages = [
    {
      title: 'Steuergerät',
      png: 'Steuergeraet'
    },
    {
      title: 'Sensor',
      png: 'Sensor'
    },
    {
      title: 'PE-Rohrleitung',
      png: 'PE-Rohr'
    },
    {
      title: 'PE-Rohrleitung (blau)',
      png: 'PE-Rohr-Blau'
    },
    {
      title: 'PE-Rohrleitung (grün)',
      png: 'PE-Rohr-Gruen'
    },
    {
      title: 'PE-Rohrleitung (weinrot)',
      png: 'PE-Rohr-Rot'
    },
    {
      title: 'PE-Rohrleitung (lila)',
      png: 'PE-Rohr-Lila'
    },
    {
      title: 'PE-Rohrleitung (gelb)',
      png: 'PE-Rohr-Gelb'
    },
    {
      title: 'PE-Rohrleitung (türkis)',
      png: 'PE-Rohr-Tuerkis'
    },
    {
      title: 'PE-Rohrleitung (orange)',
      png: 'PE-Rohr-Orange'
    },
    {
      title: 'Magnetventil',
      png: 'Magnetventil'
    },
    {
      title: 'Verteilerkonstruktion',
      png: 'Verteilerkonstruktion'
    },
    {
      title: 'Ventilbox',
      png: 'Ventilbox'
    },
    {
      title: 'Versenkregner',
      png: 'Sprinkler'
    },
    {
      title: 'Tropfrohr',
      png: 'Tropfrohr'
    },
    {
      title: 'Bogen',
      png: 'Bogen'
    },
    {
      title: 'T-Stück',
      png: 'T-Stueck'
    },
    {
      title: 'Verschraubung',
      png: 'Schraubverbindung'
    },
    {
      title: 'Swing-Joint',
      png: 'SwingJoint'
    },
    {
      title: 'Microsprüher',
      png: 'Microsprinkler'
    },
    {
      title: 'Microtropfer',
      png: 'Microtropfer'
    }
  ];

  constructor(public eventService: EventhandlerService, public menuCtrl: MenuController, public modalController: ModalController) {
  }

  ngOnInit() {}

  clickEvent(emitVal) {
    this.add(emitVal);
    this.menuCtrl.close().then((closed) => {
      if (closed) {
        this.eventService.changeMenuEmit('app-itemmenu');
      }
    });
  }

  back() {
    this.eventService.changeMenuEmit('app-itemmenu');
  }

  add(val) {
    this.eventService.addNewObject(val);
  }

  changeVisible() {
    this.visible = !this.visible;
  }
  
  commentBox() {
    this.eventService.loadNewModal('Comment');
  }

}
