import { Component, OnInit } from '@angular/core';
import { EventhandlerService } from '../../provider/eventhandler.service';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-grundstuecke',
  templateUrl: './grundstuecke.component.html',
  styleUrls: ['./grundstuecke.component.scss'],
})

export class GrundstueckeComponent implements OnInit {

  public visible = false;
  public appPages = [
    {
      title: 'Wasserentnahmestelle',
      png: 'Wasseranschluss'
    },
    {
      title: 'Pumpenstation',
      png: 'Pumpstation'
    },
    {
      title: 'Stromanschluss / Batterie',
      png: 'Stromanschluss'
    },
    {
      title: 'Grundstücksfläche',
      png: 'Grundstueck'
    },
    {
      title: 'Rasen',
      png: 'Rasen'
    },
    {
      title: 'Terrasse',
      png: 'Terrasse'
    },
    {
      title: 'Blumenbeet',
      png: 'Blumenbeet'
    },
    {
      title: 'Gemüsebeet',
      png: 'Gemuesebeet'
    },
    {
      title: 'Erde',
      png: 'Erde'
    },
    {
      title: 'Pflastersteine',
      png: 'Pflastersteine'
    }
  ];

  constructor(public eventService: EventhandlerService, public menuCtrl: MenuController) {
  }

  ngOnInit() {}

  clickEvent(emitVal) {
    if (emitVal === 'Wasseranschluss' || emitVal === 'Pumpstation' || emitVal === 'Stromanschluss') {
      this.add(emitVal);
    } else {
      this.formcreate(emitVal);
    }
    this.menuCtrl.close().then((closed) => {
      if (closed) {
        this.eventService.changeMenuEmit('app-itemmenu');
      }
    });
  }

  back() {
    this.eventService.changeMenuEmit('app-itemmenu');
  }

  add(val) {
    this.eventService.addNewObject(val);
  }

  formcreate(val){
    this.eventService.addNewForm(val);
  }

  changeVisible() {
    this.visible = !this.visible;
  }

}
